<template>
  <div class="order-management">
    <PageBread
      text="交易记录"
      :img="() => require('../../assets/image/zhuye.png')"
    ></PageBread>
    <div class="center-order">
      <div class="container">
        <PageTab
          style="height: calc(100vh - 200px)"
          @change="tabChange"
          :current="tabCurrent"
          :tabs="[
            {
              label: '消费',
              value: 1,
            },
            {
              label: '充值',
              value: 2,
            },
            {
              label: '扣款',
              value: 3,
            },
            {
              label: '提现',
              value: 4,
            },
            {
              label: '返利',
              value: 5,
            },
          ]"
        >
          <!-- 放第一栏内容 -->
          <ScrollView
            v-show="tabCurrent === 0"
            :pageInfo="tab1PageInfo"
            @loadNewData="(pageInfo) => tabLoad('1', pageInfo)"
          >
            <div
              class="bottom-transaction"
              v-for="(item, index) in noUserList"
              :key="index"
            >
              <p v-if="item">{{ item.name }}</p>
            </div>
          </ScrollView>
          <!-- 放第二栏内容 -->
          <ScrollView
            v-show="tabCurrent === 1"
            :pageInfo="tab2PageInfo"
            @loadNewData="(pageInfo) => tabLoad('2', pageInfo)"
          >
            <div
              class="bottom-transaction"
              v-for="(exo, jm) in expiredList"
              :key="jm"
            >
              <p v-if="exo">{{ exo.name }}</p>
            </div>
          </ScrollView>
          <!-- 放第三栏内容 -->
          <ScrollView
            v-show="tabCurrent === 2"
            :pageInfo="tab3PageInfo"
            @loadNewData="(pageInfo) => tabLoad('3', pageInfo)"
          >
            <div
              class="bottom-transaction"
              v-for="(rgb, wo) in userList"
              :key="wo"
            >
              <p v-if="rgb">{{ rgb.name }}</p>
            </div>
          </ScrollView>
          <!-- 放第四栏内容 -->
          <ScrollView
            v-show="tabCurrent === 3"
            :pageInfo="tab4PageInfo"
            @loadNewData="(pageInfo) => tabLoad('4', pageInfo)"
          >
            <div
              class="bottom-transaction"
              v-for="(wto, bt) in WithdrawalList"
              :key="bt"
            >
              <p v-if="wto">{{ wto.name }}</p>
            </div>
          </ScrollView>
          <!-- 放第五栏内容 -->
          <ScrollView
            v-show="tabCurrent === 4"
            :pageInfo="tab5PageInfo"
            @loadNewData="(pageInfo) => tabLoad('5', pageInfo)"
          >
            <div
              class="bottom-transaction"
              v-for="(superj, jn) in rebateList"
              :key="jn"
            >
              <p v-if="superj">{{ superj.name }}</p>
            </div>
          </ScrollView>
        </PageTab>
      
      </div>
    </div>
  </div>
</template>
<script>
import { cheapIofo } from '../../utils/coupon.js'
export default {
  name: 'login',
  data() {
    return {
      showDetail: false,
      tabCurrent: 0,
      noUserList: [],
      expiredList: [],
      userList: [],
      WithdrawalList: [],
      rebateList: [],
      tab1PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab2PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab3PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab4PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      },
      tab5PageInfo: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1
      }
    }
  },

  mounted() {},
  created() {
    this.tabLoad('1', this.tab1PageInfo)
    this.tabLoad('2', this.tab2PageInfo)
    this.tabLoad('3', this.tab3PageInfo)
    this.tabLoad('4', this.tab4PageInfo)
    this.tabLoad('5', this.tab5PageInfo)
  },
  methods: {
    tabChange(index, item) {
      this.tabCurrent = index
    },
    tabLoad(type, pageInfo) {
      this._getList(type, pageInfo)
    },
    _getList(type, pageInfo) {
      const params = {
        id: JSON.parse(localStorage.userInfo).id,
        type: type,
        ...pageInfo
      }
      this.$reqPost(cheapIofo.list, params).then((res) => {
        if (res.data.code === 200) {
          if (type === '1') {
            this.noUserList = [...this.noUserList, ...res.data.data.list]
            this.tab1PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '2') {
            this.expiredList = [...this.expiredList, ...res.data.data.list]
            this.tab2PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '3') {
            this.userList = [...this.userList, ...res.data.data.list]
            this.tab3PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '4') {
            this.WithdrawalList = [
              ...this.WithdrawalList,
              ...res.data.data.list
            ]
            this.tab4PageInfo.hasNextPage = res.data.data.hasNextPage
          }
          if (type === '5') {
            this.rebateList = [...this.rebateList, ...res.data.data.list]
            this.tab5PageInfo.hasNextPage = res.data.data.hasNextPage
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
/deep/ .tab-handler {
  padding: 0 22px;
}
</style>
